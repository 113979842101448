<template>
<div id="page" class="partners-unbind-manage-index row-start-start">
  <el-table class="pumi-left-tree-table" ref="treeTableRef" row-key="rowKey" @expand-change="expandChange" :row-class-name="getRowClass" @row-click="getDeviceInfo" :data="table.treeData" v-loading="table.treeloading" :tree-props="{ children: '_child'}">
    <el-table-column prop="txt_realname" label="姓名" />
    <el-table-column prop="txt_area_name" label="代理地区" align="center" />
    <el-table-column prop="txt_mobile" label="联系方式" align="center" />
  </el-table>
  <div class="pumi-right">
    <div class="pumir-base-info">
      <div class="pumirbi-title-line row-center-between">
        <div>基本信息</div>
        <div class="tool-btn row-center-center" @click="handleUnbind">
          <el-icon>
            <RemoveFilled />
          </el-icon>禁用该用户
        </div>
      </div>
      <div class="pumir-bi-content">
        <el-row>
          <el-col :span="8" class="row-center-start">
            <div>姓名：</div>
            <div>{{baseInfo.txt_realname||"- -"}}</div>
          </el-col>
          <el-col :span="8" class="row-center-start">
            <div>联系方式：</div>
            <div>{{baseInfo.txt_mobile||"- -"}}</div>
          </el-col>
          <el-col :span="8" class="row-center-start">
            <div>已绑定设备数量：</div>
            <div>{{baseInfo.rowCount||"- -"}}</div>
            <div v-if="baseInfo.int_agent_level != 1" @click="changeShow('unbind')" class="tool-btn has-margin row-center-center">
              <el-icon>
                <Unlock />
              </el-icon>
              全部解绑
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="row-center-start">
            <div>合伙人级别：</div>
            <div>{{baseInfo.txt_agent_level||"- -"}}</div>
          </el-col>
          <el-col :span="8" class="row-center-start">
            <div>所属地区：</div>
            <div>{{baseInfo.txt_area_name||"- -"}}</div>
          </el-col>
          <el-col :span="8" class="row-center-start">
            <div>上级合伙人：</div>
            <div>{{baseInfo.superior_name||"- -"}}</div>
            <div v-if="baseInfo.int_agent_level != 1 && baseInfo.int_agent_level != 2" @click="changeShow('change')" class="tool-btn has-margin row-center-center">
              <el-icon>
                <Unlock />
              </el-icon>
              更换上级
            </div>
          </el-col>
        </el-row>
      </div>
      <el-table class="pumi-right-table" :data="table.deviceData" v-loading="table.deviceLoading">
        <el-table-column type="index" width="50" label="序号" align="center">
          <template #default="scope">
            <template v-if="page.page == 1">{{scope.$index + 1}}</template>
            <template v-else>{{scope.$index + 1 + page.limit}}</template>
          </template>
        </el-table-column>
        <el-table-column prop="txt_realname" label="代理时间" align="center">
          <template #default="scope">
            <template v-if="baseInfo.int_agent_level==1">{{scope.row.dt_province_partner_agent||"- -"}}</template>
            <template v-else-if="baseInfo.int_agent_level==2">{{scope.row.dt_city_partner_agent||"- -"}}</template>
            <template v-else-if="baseInfo.int_agent_level==3">{{scope.row.dt_salesman_agent||"- -"}}</template>
            <template v-else>{{scope.row.dt_clinic_agent||"- -"}}</template>
          </template>
        </el-table-column>
        <el-table-column prop="txt_device_iot" label="IOT" align="center" />
        <el-table-column prop="txt_type_name" label="设备类型" align="center" />
        <el-table-column prop="txt_realname" label="地区" align="center">
          <template #default="scope">{{scope.row.txt_province_name+"/"+scope.row.txt_city_name}}</template>
        </el-table-column>
        <el-table-column prop="txt_province_partner_name" label="省代" align="center" />
        <el-table-column prop="txt_city_partner_name" label="市代" align="center" />
        <el-table-column prop="txt_salesman_name" label="业务员" align="center" />
        <el-table-column prop="txt_clinic_agent_name" label="诊所" align="center" />
      </el-table>
    </div>
    <pagination :total="page.total" @sizeChange="(e) => {page.limit=e;getDeviceInfo(null)}" @pageChange="(e) => {page.page=e;getDeviceInfo(null)}" />
  </div>
  <change :dialogData="dialog.changeData" v-if="dialog.changeShow" @close="(e)=>{changeClose(e)}" />
</div>
</template>

<script>
// 合伙人管理 - 合伙人关系变更
import { Pagination } from "components";
import { ElMessage, ElMessageBox } from 'element-plus';
import { RemoveFilled, Unlock } from "@element-plus/icons-vue";
// getCurrentInstance, 
import { onMounted, reactive, toRefs, ref } from 'vue';
import { getAllAgent, getAgentDeviceByAgentId, getAgentBaseInfo, disableAgent } from "api/apis.js";
import Change from './comps/Change.vue';
import { useStore } from 'vuex';
export default {
  components: {
    RemoveFilled,
    Unlock,
    Pagination,
    Change
  },
  setup() {
    // const instance = getCurrentInstance();
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      expands: ref([]),
      treeTableRef: ref(null),
      table: {
        treeData: [],
        deviceData: [],
        treeloading: false,
        deviceLoading: false
      },
      dialog: { changeData: {}, changeShow: false },
      page: {
        total: 0,
        limit: 10,
        page: 1
      },
      baseInfo: {},
    });

    onMounted(() => {
      getAgentTree();
    });

    // 树形表格 风琴效果
    const expandChange = (row, expanded) => {
      // 点击先清空数组 全部合起来
      // state.expands.value = [];
      // let rowKey = row["rowKey"];
      // let fRowKey = row["fRowKey"] ? row["fRowKey"] : null;
      // // 点击之前 expanded 分为 true / false
      // // ture 代表之前是合起状态 现在要展开起来
      // if (expanded) {
      //   // 找到父节点一起放进数组
      //   state.expands.value.push(rowKey);
      //   if (fRowKey) {
      //     state.expands.value.push(fRowKey);
      //   }
      // } else {
      //   // false 代表要合起来，
      //   //如果是根节点，根节点的pid不在列表所以无效,就算放进数组也没用
      //   // 如果是叶子节点 把pid 放进数组 代表只合起自己，当前节点的根节点还是会展开
      //   state.expands.value.push(fRowKey)
      // }

      if (expanded) {
        let rowKey = row["rowKey"].split("-")[0];
        for (let i = 0; i < state.table.treeData.length; i++) {
          let tableRowKey = state.table.treeData[i]["rowKey"].split("-")[0];
          if (rowKey != tableRowKey) {
            state.treeTableRef.toggleRowExpansion(state.table.treeData[i], false);
          }
        }
      }
    };

    // 获取左侧代理树
    const getAgentTree = () => {
      state.table.treeloading = true;
      getAllAgent({ data_type: "tree" }).then(response => {
        if (response.code == 200) {
          state.table.treeData = response.data;
          // 首次进入，默认第一行被选中，同时获取右侧基本信息
          // 禁用操作后，重置左侧表格树
          state.table.treeData[0].focus = true;
          getDeviceInfo(state.table.treeData[0]);

          // 递归，设置每一条数据 的key
          setKeyLoop();
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.treeloading = false;
      })
    };

    // 禁用 用户
    const handleUnbind = () => {
      ElMessageBox.confirm(
        `是否禁用当前用户【${state.baseInfo.txt_realname}】，禁用后不可恢复！`,
        '提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
      ).then(() => {
        state.table.treeloading = true;
        state.table.deviceLoading = true;
        disableAgent({
          agent_id: state.baseInfo.t_agent_id,
          txt_creator: store.getters.userInfo.name || "系统用户",
          dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            getAgentTree();
          } else {
            ElMessageBox.alert(response.msg, "", {
              confirmButtonText: "确定",
              callback: (() => {})
            })
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.treeloading = false;
          state.table.deviceLoading = false;
        })
      })
    };

    // 根据 代理ID 获取设备代理情况
    const getDeviceInfo = (row) => {
      let lastUnbindFocusRow = JSON.parse(localStorage.getItem("lastUnbindFocusRow"));
      if (row) {
        row.focus = true;
        if (lastUnbindFocusRow && lastUnbindFocusRow.t_agent_id != row.t_agent_id) {
          // 走递归，改变树表格样式
          goLoop(lastUnbindFocusRow);
        }
        localStorage.setItem("lastUnbindFocusRow", JSON.stringify(row));
        state.baseInfo = row;
        state.table.deviceLoading = true;
        // 获取代理基本信息
        getAgentBaseInfo({ agent_id: row.t_agent_id }).then(response => {
          if (response.code == 200) {
            state.baseInfo = response.data;
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          // 获取设备代理情况
          getAgentDeviceByAgentId({ agent_id: row.t_agent_id, page: state.page.page, limit: state.page.limit }).then(response => {
            if (response.code == 200) {
              if (response.data) {
                state.table.deviceData = response.data.data;
                state.page.total = response.data.rowCount;
                state.baseInfo.rowCount = response.data.rowCount;
              } else {
                state.table.deviceData = [];
                state.page.total = 0;
                state.baseInfo.rowCount = null;
              }
            } else {
              ElMessage.error(response.msg);
            }
          }).catch(e => {
            console.error(e);
          }).finally(() => {
            state.table.deviceLoading = false;
          })
        })
      } else {
        state.table.deviceLoading = true;
        // 获取设备代理情况
        getAgentDeviceByAgentId({ agent_id: lastUnbindFocusRow.t_agent_id, page: state.page.page, limit: state.page.limit }).then(response => {
          if (response.code == 200) {
            if (response.data) {
              state.table.deviceData = response.data.data;
              state.page.total = response.data.rowCount;
              state.baseInfo.rowCount = response.data.rowCount;
            } else {
              state.table.deviceData = [];
              state.page.total = 0;
              state.baseInfo.rowCount = null;
            }
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.deviceLoading = false;
        })
      }
    };

    // 递归，设置每一条数据 的key
    const setKeyLoop = () => {
      for (let i = 0; i < state.table.treeData.length; i++) {
        let item = state.table.treeData[i];
        item.rowKey = i.toString();
        if ((item._child || []).length > 0) {
          setLoop(item._child, item.rowKey);
        }
      }

      function setLoop(data, rowKey) {
        for (let i = 0; i < data.length; i++) {
          data[i].rowKey = rowKey + "-" + i.toString();
          data[i].fRowKey = rowKey;
          if ((data[i]._child || []).length > 0) {
            setLoop(data[i]._child, data[i].rowKey);
          }
        }
      }
    };

    // 递归，修改树表格选中/未选中状态 UI 
    const goLoop = (lastUnbindFocusRow) => {
      function loop(data) {
        if (data instanceof Array) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].t_agent_id == lastUnbindFocusRow.t_agent_id) {
              data[i].focus = false;
              break;
            } else if (data[i]._child) {
              loop(data[i]._child);
            }
          }
        } else {
          if (data.t_agent_id == lastUnbindFocusRow.t_agent_id) {
            data.focus = false;
          }
        }
      }

      loop(state.table.treeData);
      // instance.ctx.$forceUpdate();
    };

    // 更换下级合伙人及设备 - 全部解绑
    // 更换上级 
    const changeShow = (type) => {
      let lastUnbindFocusRow = JSON.parse(localStorage.getItem("lastUnbindFocusRow"));
      lastUnbindFocusRow.type = type;
      state.dialog.changeData = lastUnbindFocusRow;
      state.dialog.changeShow = true;
    };

    // dialog 关闭事件
    const changeClose = e => {
      state.dialog.changeShow = false;
      state.dialog.changeData = {};
      if (e == true) {
        getAgentTree()
      }
    };

    // 设置 代理树 表格 行样式
    const getRowClass = ({ row }) => {
      if (row.focus) {
        return "tree-table-row-class";
      } else {
        return "";
      }
    };

    return {
      ...toRefs(state),
      getRowClass,
      getDeviceInfo,
      goLoop,
      handleUnbind,
      changeShow,
      changeClose,
      expandChange,
      setKeyLoop
    }
  }
}
</script>

<style lang="scss">
.partners-unbind-manage-index {
  .pumi-left-tree-table {
    width: 40%;
    padding-right: 6px;
    box-sizing: border-box;
    border-right: 1px solid #ebeef5;
  }

  .pumi-right {
    width: 60%;
    height: 100%;
    padding-left: 6px;
    box-sizing: border-box;

    .pumir-base-info {
      height: calc(100% - 40px);

      .pumirbi-title-line {
        padding: 6px 10px 0 10px;
        font-weight: bold;
      }

      .pumir-bi-content {
        padding: 30px 55px;

        .el-row {
          font-size: 14px;

          &:first-child {
            margin-bottom: 14px;
          }

          div :first-child {
            font-weight: bold;
          }
        }
      }

      .pumi-right-table {
        height: calc(100% - 140px);
      }
    }
  }

  .el-table {
    height: 100%;
    cursor: pointer;

    tbody {
      .tree-table-row-class:hover>td {
        background-color: #89c3f0 !important;
      }
    }

    .el-table__row {
      cursor: pointer;

      &.tree-table-row-class {
        background-color: #89c3f0 !important;
      }
    }
  }

  .tool-btn {
    cursor: pointer;
    color: #e7c82f;
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;

    .el-icon {
      margin-right: 2px;
    }

    &.has-margin {
      margin-left: 6px;
    }
  }
}
</style>
