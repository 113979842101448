<template>
<el-dialog v-model="visible" :title="title" :before-close="close" :close-on-click-modal="false" custom-class="change-dialog-for-unbind">
  <el-table :data="table.data" v-loading="table.loading">
    <el-table-column label="选择" width="55" align="center">
      <template #default="scope">
        <el-checkbox v-model="scope.row.checked" @change="handleChecked(scope.row)" />
      </template>
    </el-table-column>
    <el-table-column prop="txt_realname" label="合伙人姓名" align="center" />
    <el-table-column prop="txt_mobile" label="联系方式" align="center" />
  </el-table>
  <template #footer>
    <div class="dialog-footer">
      <el-button @click="close(false)" type="info">取 消</el-button>
      <el-button :disabled="confirmDis" @click="close(true)" type="primary">确 认</el-button>
    </div>
  </template>
</el-dialog>
</template>

<script>
// 更换合伙人
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import { computed, onMounted, reactive, toRefs } from 'vue';
import { getReplaceableAgent, goReplaceableAgent, getReplacePagent, goReplaceablePagent } from "api/apis.js";
export default {
  props: {
    dialogData: { type: Object },
  },
  setup(prop, ctx) {
    const store = useStore();
    let moment = require("moment");
    const state = reactive({
      visible: true,
      table: {
        data: [],
        loading: false
      },
      title: "- -",
      confirmDis: computed(() => {
        let result = (state.table.data || []).filter(t => t.checked).length == 0;
        return result
      })
    });

    onMounted(() => {
      if (prop.dialogData.type == "unbind") {
        state.title = "将" + prop.dialogData.txt_realname + "全部下级合伙人及设备关系移交至：";
      } else {
        state.title = "更换当前合伙人" + prop.dialogData.txt_realname + "的上级为：";
      }
      getTableDataByType();
    });

    // 表格选择事件
    const handleChecked = row => {
      state.table.data.forEach(item => {
        if (item.t_agent_id != row.t_agent_id) {
          item.checked = false;
        }
      })
    };

    // 获取可替换的合伙人
    const getTableDataByType = () => {
      state.table.loading = true;
      if (prop.dialogData.type == "unbind") {
        getReplaceableAgent({
          agent_id: prop.dialogData.t_agent_id
        }).then(response => {
          if (response.code == 200) {
            state.table.data = response.data;
            if (state.table.data) {
              state.table.data.forEach(item => item.checked = false)
            }
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      } else {
        getReplacePagent({
          agent_id: prop.dialogData.t_agent_id,
          target_pagent_id: prop.dialogData.t_pagent_id
        }).then(response => {
          if (response.code == 200) {
            state.table.data = response.data;
            if (state.table.data) {
              state.table.data.forEach(item => item.checked = false);
            }
          } else {
            ElMessage.error(response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      }
    }

    const close = (type) => {
      if (type == true) {
        state.table.loading = true;
        let target_agent_id = state.table.data.filter(t => t.checked)[0].t_agent_id;
        if (prop.dialogData.type == "unbind") {
          goReplaceableAgent({
            origin_agent_id: prop.dialogData.t_agent_id,
            target_agent_id,
            txt_creator: store.getters.userInfo.name || "系统用户",
            dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }).then(response => {
            if (response.code == 200) {
              ElMessage.success("解绑成功");
              setTimeout(() => {
                ctx.emit("close", type);
              }, 1000)
            } else {
              ElMessage.error(response.msg);
            }
          }).catch(e => {
            console.error(e);
          })
        } else {
          let target_pagent_id = state.table.data.filter(t => t.checked)[0].t_agent_id;
          goReplaceablePagent({
            agent_id: prop.dialogData.t_agent_id,
            target_pagent_id,
            txt_creator: store.getters.userInfo.name || "系统用户",
            dt_create: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
          }).then(response => {
            if (response.code == 200) {
              ElMessage.success("解绑成功");
              setTimeout(() => {
                ctx.emit("close", type);
              }, 1000)
            } else {
              ElMessage.error(response.msg);
            }
          }).catch(e => {
            console.error(e);
          })
        }
      } else {
        ctx.emit("close", type);
      }
    }

    return {
      ...toRefs(state),
      close,
      getTableDataByType,
      handleChecked
    }
  }
}
</script>

<style lang="scss">
.change-dialog-for-unbind {
  width: 600px;
  height: 550px;
}
</style>
